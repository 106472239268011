<script setup lang="ts">
interface Props {
  iconName?: string;
}

const { iconName } = withDefaults(defineProps<Props>(), {
  iconName: 'i-ph-eye-slash',
});
</script>

<!-- NuxtUI doesn't allow links in the label for empty state, so we customize the empty state below -->
<!-- https://ui.nuxt.com/components/table-->
<template>
  <div
    class="flex flex-col items-center justify-center flex-1 px-6 py-20 sm:px-14"
  >
    <UIcon :name="iconName" class="text-3xl text-gray-400 dark:text-gray-500" />
    <p class="text-center text-gray-900 dark:text-white">
      <slot></slot>
    </p>
  </div>
</template>
